import React from "react";

function About() {
  return (
    <section className="tc-about-style7">
      <div className="container">
        <div className="row gx-0 justify-content-between">
          <div className="col-lg-3">
            <div className="rotate-box" data-speed="1" data-lag="0.4">
              <a
                href="#"
                className="rotate-circle fsz-30 rotate-text d-inline-block text-uppercase"
              >
                <svg className="textcircle" viewBox="0 0 500 500">
                  <defs>
                    <path
                      id="textcircle"
                      d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                    ></path>
                  </defs>
                  <text>
                    <textPath xlinkHref="#textcircle" textLength="900">
                      Luxe Food & Drinks - Authentic Flavor -
                    </textPath>
                  </text>
                </svg>
              </a>
              <img
                src="/innerpages/assets/img/leafs.png"
                alt=""
                className="icon"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="text fsz-22 fw-500 mb-20">
                At Luxe Food & Drinks, we are dedicated to bringing the rich,
                authentic flavors of Nigeria into your kitchen. With a focus on
                convenience, quality, and sustainability, we offer instant meals
                and beverages that celebrate traditional recipes and support
                local farmers.
              </div>
              <div className="text fsz-22 fw-500 mb-20">
                Whether it's our flavorful Jollof rice, hearty Moi Moi, or
                refreshing Zobo drink, we promise a delicious experience with
                every bite.
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="numbers mt-5 mt-lg-0">
              <div
                className="number-card wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <h2 className="numb"> 500K + </h2>
                <small> Meals Served </small>
              </div>
              <div
                className="number-card wow zoomIn slow"
                data-wow-delay="0.6s"
              >
                <h2 className="numb"> 100+ </h2>
                <small> Local Farmers Supported </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
