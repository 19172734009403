import React from "react";

function Header() {
  return (
    <header className="tc-inner-header-style1 pb-100">
      <div className="container">
        <div className="info">
          <h1 className="js-title"> contact </h1>
          <div className="text fsz-18 color-666">
            Got questions or want to partner with us? Whether it's about our
            products, <br /> becoming a distributor, or learning more, we’re
            here to help{" "}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
