import React from "react";

function Process() {
  return (
    <section className="tc-process-style2">
      <div className="container">
        <h2 className="fsz-45 fw-500 mb-80 text-center">
          More
          <span className="sub-font fst-italic color-orange1 fw-400">
            About Us
          </span>
        </h2>
        <div className="content">
          <div className="row">
            <div className="col-lg-5">
              <div className="accordion-side wow fadeInUp slow">
                <div className="accordion" id="accordionProcess">
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        <span className="num"> 1 / </span> <h3> Why Luxe </h3>
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          At Luxe Food & Drinks Ltd, we bring the rich,
                          authentic flavors of Nigeria to your table. From
                          refreshing Zobo to instant meals like Jollof rice and
                          Moi Moi, our mission is to make traditional Nigerian
                          cuisine both accessible and convenient for busy
                          professionals, students, and families everywhere. We
                          believe in preserving the essence of Nigerian culture
                          in every product, using locally sourced ingredients
                          that support our farmers and contribute to
                          sustainability. Our advanced packaging ensures
                          extended shelf life while maintaining the quality and
                          freshness of our meals and drinks, making it easier
                          for you to enjoy the taste of home anytime, anywhere.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        <span className="num"> 2 / </span> <h3> Our Story </h3>
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          Luxe Food & Drinks was founded with a passion for
                          Nigerian flavors and the desire to share them with the
                          world. Over the years, we’ve evolved, but our focus
                          remains the same: offering premium products that honor
                          the diverse culinary heritage of Nigeria. We are
                          deeply committed to supporting the local agricultural
                          value chain, promoting sustainability, and delivering
                          products that combine tradition with modern
                          convenience.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        <span className="num"> 3 / </span>{" "}
                        <h3> Our Mission </h3>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          To make authentic Nigerian food and drinks easy,
                          accessible, and enjoyable, while promoting sustainable
                          practices and supporting local farmers. Luxe Food &
                          Drinks is where tradition meets modern convenience,
                          allowing you to enjoy the true taste of Nigeria,
                          wherever you are.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div className="accordion-item">
                    <div className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                      >
                        <span className="num"> 4 / </span> <h3> Handover </h3>
                      </button>
                    </div>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                          After surveying and obtaining the necessary
                          information, we will create a preliminary design
                          including technical drawings, 3D images of the
                          interior and provide a 3D VR experience to help
                          customers get a visual view of their project.
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>
                <a href="#" className="butn bg-white  mt-50 hover-bg-black">
                  <span>
                    Shop Now
                    <i className="ms-2 fal fa-long-arrow-right ico-45"></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="imgs">
                <div className="img" data-lag="0.2">
                  <img
                    src="/innerpages/assets/img/process/plant.jpeg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Authentic </span>
                </div>
                <div className="img" data-lag="0.4">
                  <img
                    src="/innerpages/assets/img/process/flavor.jpeg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Flavorful </span>
                </div>
                <div className="img" data-lag="0.3">
                  <img
                    src="/innerpages/assets/img/process/Convenient.jpeg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Convenient </span>
                </div>
                <div className="img" data-lag="0.5">
                  <img
                    src="/innerpages/assets/img/process/clock.jpeg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> instant </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/innerpages/assets/img/prc_bg.png"
        alt=""
        className="bg"
        data-speed="1.2"
      />
    </section>
  );
}

export default Process;
