import React from "react";
import { Link } from "react-router-dom";

function Menu() {
  return (
    <>
      <div className="side_menu4_overlay"></div>
      <div className="side_menu4_overlay2"></div>
      <div className="side_menu_style4">
        <div className="content">
          <div className="main_links">
            <ul>
              <li>
                <Link to="/home" className="main_link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="main_link">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="" className="main_link">
                  Shop
                </Link>
              </li>
              <li>
                <Link to="/contact" className="main_link">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="" className="main_link">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/*<img
          src="/innerpages/assets/img/chat_pat2.png"
          alt=""
          className="side_shape"
        />
        <img
          src="/innerpages/assets/img/chat_pat2.png"
          alt=""
          className="side_shape2"
        />*/}
        <span className="clss">
          <i className="la  la-times"></i>
        </span>
      </div>
    </>
  );
}

export default Menu;
