import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="tc-footer-style1 border-top">
      <div className="container">
        <div className="top-content section-padding">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="info-side">
                <div className="text fsz-24 color-333 lh-3 fw-600">
                  Luxe Food & Drinks Ltd Bringing authentic Nigerian flavors to
                  your table, with convenience, quality, and sustainability at
                  the heart of everything we do.
                </div>
                <div className="foot-social mt-50">
                  <a href="#">
                    <i className="fab fa-x-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-2">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600">
                  {" "}
                  Customer Support{" "}
                </h5>
                <ul className="footer-links">
                  <li>
                    <p>support@luxefd.com</p>
                  </li>
                  <li>
                    <p> (+234) 123 456 7890 </p>
                  </li>
                  <li>
                    <p> Monday – Friday, 9 AM – 6 PM (WAT) </p>
                  </li>
                </ul>
              </div>
              <div className="branch-card">
                <h5 className="mb-20 mt-5 fw-600"> Business Inquiries</h5>
                <ul className="footer-links">
                  <li>
                    <p> business@luxefd.com </p>
                  </li>
                  <li>
                    <p> (+234) 123 456 7890 </p>
                  </li>
                  <li>
                    <p> Monday – Friday, 9 AM – 6 PM (WAT) </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600">Our Location</h5>
                <ul className="footer-links">
                  <li>
                    <p>123 Food Avenue, Lagos, Nigeria</p>
                  </li>
                  <li>
                    <p> (021) 3456789 </p>
                  </li>
                  <li>
                    <p> Monday – Friday, 9 AM – 6 PM (WAT) </p>
                  </li>
                </ul>
              </div>
              <div className="branch-card">
                <h5 className="mb-20 mt-5 fw-600"> Helps </h5>
                <ul className="footer-links">
                  <li>
                    <p> Term & Conditions </p>
                  </li>
                  <li>
                    <p> Partner Policy </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13">
                © 2024 Luxe Food & Drinks. All Right Reserved
              </p>
            </div>
            <div className="col-lg-6">
              <div className="foot-links mt-4 mt-lg-0">
                <Link to="/home">Home</Link>
                <Link to="/about">About us</Link>
                <a href="#"> Shop</a>
                <Link to="/contact">Contact</Link>
                <a href="#"> Blog </a>
                <a href="#"> LogIn </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
