import React from "react";

function Services() {
  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="title mb-80 text-center">
            <p className="color-666 text-uppercase wow"> Why luxe F&D </p>
          </div>
          <div className="services">
            <div className="row">
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    <i className="ti-lock" aria-hidden="true"></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Authentic Flavors </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ricepot.jpeg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    We bring you the true taste of Nigeria, using traditional
                    recipes that capture the richness and diversity of our
                    cuisine.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="icon">
                    <i className="ti-thumb-up"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Convenience Without Compromise
                  </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/rush.jpeg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Our instant meals and beverages are crafted to fit your busy
                    lifestyle without sacrificing quality or taste. Enjoy
                    delicious, ready-to-eat Jollof rice, Moi Moi, and Zobo in
                    minutes.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="icon">
                    <i className="ti-comments-smiley"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">Sustainably Sourced </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/farmer.jpeg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Luxe F&D is committed to supporting local farmers and using
                    fresh, sustainable ingredients. Every product you enjoy is a
                    step towards strengthening our community and promoting a
                    greener future.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className="ti-package"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">Quality You Can Trust</h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/pack.jpeg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    We ensure the highest standards of packaging and food
                    safety, extending shelf life while preserving the freshness
                    and flavor of our products.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              href="#"
              className="butn  mt-80 hover-bg-black bg-orange1 text-white"
            >
              <span>
                Join Us as a Distributor
                <i className="small ms-1 ti-arrow-top-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="ser-img">
        <img src="/home1/assets/img/services/ser.jpg" alt="Distributor" />
      </div>
    </section>
  );
}

export default Services;
