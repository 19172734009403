import React from "react";

function Experience() {
  return (
    <section className="tc-experience-style1 section-padding-x">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-2">
            <div className="exp-num mb-100 wow zoomIn" data-wow-delay="0.3s">
              <h5 className="fsz-18 text-uppercase">
                years of <br /> experience
              </h5>
              <h2 className="num"> 5 </h2>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="img wow">
              <img
                src="/home1/assets/img/herbs.jpeg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="info wow fadeInUp" data-wow-delay="0.3s">
              <h3 className="fsz-45 fw-600 mb-30"> Est. 2019 </h3>
              <div className="text fsz-15 color-666">
                At Luxe Food & Drinks, we are dedicated to bringing the rich,
                authentic flavors of Nigeria into your kitchen. With a focus on
                convenience, quality, and sustainability, we offer instant meals
                and beverages that celebrate traditional recipes and support
                local farmers. Whether it's our flavorful Jollof rice, hearty
                Moi Moi, or refreshing Zobo drink, we promise a delicious
                experience with every bite.
              </div>
              <a href="#" className="butn  mt-50 hover-bg-black bg-white">
                <span>
                  Read More <i className="small ms-1 ti-arrow-top-right"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="rotate-txt justify-content-lg-end">
              <ul>
                <li>
                  <a href="#"> hello@luxefoodanddrink.co </a>
                </li>
                <li>
                  <a href="#"> (234) 34567890 </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img src="/home1/assets/img/c_line.png" alt="" className="c-line wow" />
    </section>
  );
}

export default Experience;
