import React from "react";

function Team() {
  return (
    <section className="tc-team-style1">
      <div className="container">
        <div className="mb-80 js-splittext-lines">
          <h2 className="fsz-45"> Meet the Luxe F&D Team </h2>
        </div>
        <div className="members">
          <div className="row">
            <div className="col-lg-2">
              <div className="member-colmn">
                <div className="team-card wow zoomIn">
                  <div className="img">
                    <img
                      src="/home1/assets/img/team/team1.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <h5 className="title">
                    {" "}
                    Adewale Adebayo <br /> COO{" "}
                  </h5>
                </div>
                <div className="team-card wow zoomIn" data-wow-delay="0.1s">
                  <div className="img">
                    <img
                      src="/home1/assets/img/team/team2.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <h5 className="title">
                    {" "}
                    Temidayo Olumide <br /> Head of Product{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="member-colmn">
                <div className="team-card wow zoomIn" data-wow-delay="0.2s">
                  <div className="img">
                    <img
                      src="/home1/assets/img/team/team3.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <h5 className="title">
                    {" "}
                    Adebola Akinyemi <br /> Founder & CEO{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="member-colmn">
                <div className="team-card wow zoomIn" data-wow-delay="0.3s">
                  <div className="img">
                    <img
                      src="/home1/assets/img/team/team4.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <h5 className="title">
                    {" "}
                    Chika Okafor <br /> Co - Founder{" "}
                  </h5>
                  <h6 className="title"> </h6>
                </div>
                <div className="team-card wow zoomIn" data-wow-delay="0.4s">
                  <div className="img">
                    <img
                      src="/home1/assets/img/team/team5.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <h5 className="title">
                    {" "}
                    Amara Nwosu <br /> Director of Marketing{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 order-last order-lg-0">
              <div className="member-colmn">
                <div className="team-card wow zoomIn" data-wow-delay="0.5s">
                  <div className="img">
                    <img
                      src="/home1/assets/img/team/team6.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <h5 className="title">
                    {" "}
                    Ngozi Eze <br /> Head of Sales{" "}
                  </h5>
                </div>
                <a
                  href="#"
                  className="team-card team-text wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  <div className="img">
                    <h5 className="title">
                      {" "}
                      <div className="con">
                        {" "}
                        <span>
                          {" "}
                          See All Our <br /> Member{" "}
                        </span>{" "}
                        <span className="arrow">
                          {" "}
                          <i className="ti-arrow-top-right"></i>{" "}
                        </span>{" "}
                      </div>{" "}
                    </h5>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="member-colmn">
                <div className="team-card wow zoomIn" data-wow-delay="0.7s">
                  <div className="img">
                    <img
                      src="/home1/assets/img/team/team7.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <h5 className="title">
                    {" "}
                    Tobi Balogun <br /> Sustainability Manager{" "}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="numbers">
          <div className="row gx-5">
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.2s">
                <h3 className="perc"> 95% </h3>
                <p>
                  {" "}
                  Happy Customer and <br /> Repeating{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.4s">
                <h3 className="perc"> 3M+ </h3>
                <p>
                  {" "}
                  Meals and drink served
                  <br /> in 5 Years{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="number-card wow fadeInUp" data-wow-delay="0.6s">
                <h3 className="perc"> 45% </h3>
                <p>
                  {" "}
                  Growth <br /> In online sales{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
