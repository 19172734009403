import React from "react";

function Testimonials() {
  return (
    <section className="tc-testimonials-style1">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h6 className="fsz-18 text-uppercase lh-4">
              What Our Customers <br /> Are Saying
            </h6>
            <div className="lg-icon color-orange1">
              <i className="la la-quote-right"></i>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="tc-clients-style1">
              <div className="clients-slider1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-45 fw-600 lh-2 ">
                        “Luxe F&D has brought the taste of home back into my
                        life! As a busy professional, their Jollof rice is a
                        lifesaver—quick to prepare and full of authentic flavor.
                        I couldn’t ask for more!”
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/Chidi.jpeg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                            Chidi
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                            Lagos, Nigeria
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-45 fw-600 lh-2 ">
                        “I was amazed by the convenience and taste of their Moi
                        Moi. It’s hard to find something that balances tradition
                        and ease like Luxe F&D does. Now, my whole family enjoys
                        it regularly!”
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/Aisha.jpeg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                            Aisha
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                            Abuja, Nigeria
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-45 fw-600 lh-2 ">
                        “Living abroad, it’s tough to find good Nigerian food.
                        Luxe F&D’s Zobo drink and instant meals have been a
                        game-changer. It’s like having a piece of home wherever
                        I am.”
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/Tunde.jpeg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                            Tunde
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                            London, UK
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-controls">
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marq-slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <a href="#"> Tasty </a>
          </div>
          <div className="swiper-slide">
            <a href="#"> Fast </a>
          </div>
          <div className="swiper-slide">
            <a href="#"> Pure </a>
          </div>
        </div>
      </div>
      <img src="/home1/assets/img/c_line3.png" alt="" className="c-line" />
    </section>
  );
}

export default Testimonials;
